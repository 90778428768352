<template>
  <div class="themelogout" :style="{background: $store.getters.getColor('bgColorSecundary')}">
    <v-container class="pa-5 pt-0 mt-5">
      <v-row no-gutters class="mb-0">
        <v-col>
          <h1 class="white--text title font-weight-bold">Selecione as corretoras</h1>
          <button class="button-search mt-4" @click="dialog = true">
            <div>
              <p>Selecionar corretoras</p>
              <span>Clique aqui</span>
            </div>
            <v-icon color="#949494">mdi-magnify</v-icon>
          </button>
        </v-col>
      </v-row>
      <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
        <v-progress-circular color="white" indeterminate></v-progress-circular>
      </div>
      <v-row no-gutters class="mb-0" v-else>
        <v-col>
          <div class="vinculacao-title"  v-if="!dataUserState.isCorretorInterno">
              <h2>Vinculadas</h2>
              <span>{{corretoras.length}}/{{totalCorretoras}}</span>
          </div>
          <div class="vinculacao-title" v-else></div>
          <ul class="list-vinculacao" v-if="corretoras && corretoras.length">
            <li v-for="(corretora, index) in corretoras" :key="index">
              {{corretora.nomeFantasia}}
              <v-btn icon v-if="!dataUserState.isCorretorInterno">
                <v-icon color="#F44F45" @click="removeCorretora(corretora.id)">mdi-minus-circle</v-icon>
              </v-btn>
            </li>
          </ul>
          <span v-else class="vinculacao-nocontent">Nenhuma</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-0" v-if="corretoras.length">
        <v-col>
          <v-btn
            block
            large
            type="submit"
            elevation="10"
            color="white"
            class="text-none mb-4 mt-5 font-weight-bold"
            @click="submit"
            :loading="loadingSubmit"
          >
            <b class="primary--text">Avançar</b>
             <span slot="loader">
                <v-progress-circular
                  indeterminate
                  :width="3"
                  color="primary"
                ></v-progress-circular>
              </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" class="d-flex">
      <div class="themelogout" :style="{background: $store.getters.getColor('bgColorSecundary')}">
        <Corretoras
        v-if="dialog"
        :regiao="dataEnderecoState.estado"
        @back="dialog = false"
        @complete="corretorasSelecionadas"
        :total="totalCorretoras"
        ></Corretoras>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import usuarioService from '@/services/usuarioService';
import corretoraService from '@/services/corretoraService';
import Corretoras from './Corretoras.vue';

export default {
  name: 'SelecionarCorretoras',
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      corretoras: [],
      totalCorretoras: 10,
      corretor: {},
      dialog: false,
    };
  },
  components: {
    Corretoras,
  },
  computed: {
    ...mapState({
      dataUserState: (state) => state.user.profile,
      dataEnderecoState: (state) => state.user.profile.endereco,
      dataCorretorasState: (state) => state.cadastroCorretor.corretoras,
    }),
  },
  watch: {
    dataUserState: {
      immediate: true,
      handler: 'setDataUser',
      deep: true,
    },
    dataCorretorasState: {
      immediate: true,
      handler: 'setCorretoras',
      deep: true,
    },
  },
  created() {
    this.getCorretoras();
  },
  methods: {
    ...mapActions({
      removeCorretora: 'cadastroCorretor/removeCorretora',
      setAllCorretoras: 'cadastroCorretor/setAllCorretoras',
    }),
    async getCorretoras() {
      this.loading = true;
      try {
        const corretorasExcluir = await this.getCorretorasAtuais();
        let data = await corretoraService.getCorretorasPorCpf({ cpf: this.dataUserState.cpf });

        data = data.filter((c) => corretorasExcluir.indexOf(c.cpfCnpj) === -1);
        this.loading = false;

        if (data.length > 0) {
          this.corretoras = data;
        }

        this.setAllCorretoras(data);
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.loading = false;
      }
    },
    async getCorretorasAtuais() {
      const corretoras = await usuarioService.listaCorretorasVinculadas({ cpf: this.dataUserState.cpf });
      return corretoras.map((c) => c.cpfCnpj);
    },
    setDataUser() {
      this.form = {
        ...this.form,
        corretor: this.$cleanObserver(this.dataUserState),
      };
    },
    corretorasSelecionadas(corretoras) {
      this.corretoras = corretoras;
      this.dialog = false;
      this.setAllCorretoras(this.corretoras);
    },
    async submit() {
      try {
        this.setAllCorretoras(this.corretoras);
        if (this.form.corretor.etapaCadastroInicial && this.form.corretor.etapaCadastroInicial !== '') {
          if (parseInt(this.form.corretor.etapaCadastroInicial, 10) < 4) {
            this.form.corretor.etapaCadastroInicial = '4';
          }
        } else {
          this.form.corretor.etapaCadastroInicial = '4';
        }
        this.loadingSubmit = true;
        this.form.corretoras = this.corretoras;

        await corretoraService.solicitaAssociacaoCorretoras({ cpf: this.dataUserState.cpf, corretoras: this.corretoras });

        this.$router.push({ name: 'areaLogada.meuPerfil', params: {} });
      } catch (error) {
        this.loadingSubmit = false;

        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao salvar foto, tente novamente mais tarde',
        });
      }
    },
    setCorretoras() {
      this.corretoras = this.$cleanObserver(this.dataCorretorasState);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables";

.button-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
  transition: all ease 0.3s;
  position: relative;
  outline: none;
  &:before {
    z-index: 1;
    transition: all ease 0.3s;
    content: "";
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
  }
  &:hover {
    &:before {
      opacity: 1;
      background: rgba($color: $azul-qualicorp-hex, $alpha: 0.12);
    }
  }
  &:active {
    &:before {
      opacity: 1;
      background: rgba($color: $azul-qualicorp-hex, $alpha: 0.4);
    }
  }

  > div {
    z-index: 2;
    line-height: 1;
    text-align: left;
    p {
      font-size: 14px;
      margin-bottom: 5px;
      color: #000;
    }
    span {
      font-size: 12px;
      color: #808080;
    }
  }
}

.vinculacao-title {
  margin: 20px 0;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-bottom: 2px solid rgba($color: #fff, $alpha: 0.5);
  h2 {
    font-size: 16px;
    margin: 0;
  }
  span {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }
}
.vinculacao-nocontent {
  font-weight: bold;
  color: rgba($color: #fff, $alpha: 0.5);
}
.list-vinculacao {
  padding: 0;
  list-style: none;
  margin: 0;
  li {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba($color: #fff, $alpha: 0.5);
    font-weight: bold;
  }
}
</style>
