var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "themelogout",
      style: { background: _vm.$store.getters.getColor("bgColorSecundary") },
    },
    [
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0 mt-5" },
        [
          _c(
            "v-row",
            { staticClass: "mb-0", attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c(
                  "h1",
                  { staticClass: "white--text title font-weight-bold" },
                  [_vm._v("Selecione as corretoras")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button-search mt-4",
                    on: {
                      click: function ($event) {
                        _vm.dialog = true
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("p", [_vm._v("Selecionar corretoras")]),
                      _c("span", [_vm._v("Clique aqui")]),
                    ]),
                    _c("v-icon", { attrs: { color: "#949494" } }, [
                      _vm._v("mdi-magnify"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "white", indeterminate: "" },
                  }),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "mb-0", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", [
                    !_vm.dataUserState.isCorretorInterno
                      ? _c("div", { staticClass: "vinculacao-title" }, [
                          _c("h2", [_vm._v("Vinculadas")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.corretoras.length) +
                                "/" +
                                _vm._s(_vm.totalCorretoras)
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "vinculacao-title" }),
                    _vm.corretoras && _vm.corretoras.length
                      ? _c(
                          "ul",
                          { staticClass: "list-vinculacao" },
                          _vm._l(_vm.corretoras, function (corretora, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                _vm._v(
                                  " " + _vm._s(corretora.nomeFantasia) + " "
                                ),
                                !_vm.dataUserState.isCorretorInterno
                                  ? _c(
                                      "v-btn",
                                      { attrs: { icon: "" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "#F44F45" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeCorretora(
                                                  corretora.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-minus-circle")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("span", { staticClass: "vinculacao-nocontent" }, [
                          _vm._v("Nenhuma"),
                        ]),
                  ]),
                ],
                1
              ),
          _vm.corretoras.length
            ? _c(
                "v-row",
                { staticClass: "mb-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none mb-4 mt-5 font-weight-bold",
                          attrs: {
                            block: "",
                            large: "",
                            type: "submit",
                            elevation: "10",
                            color: "white",
                            loading: _vm.loadingSubmit,
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _c("b", { staticClass: "primary--text" }, [
                            _vm._v("Avançar"),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "loader" }, slot: "loader" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  width: 3,
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "d-flex",
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "themelogout",
              style: {
                background: _vm.$store.getters.getColor("bgColorSecundary"),
              },
            },
            [
              _vm.dialog
                ? _c("Corretoras", {
                    attrs: {
                      regiao: _vm.dataEnderecoState.estado,
                      total: _vm.totalCorretoras,
                    },
                    on: {
                      back: function ($event) {
                        _vm.dialog = false
                      },
                      complete: _vm.corretorasSelecionadas,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }