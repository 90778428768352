<template>
  <fragment>
    <v-toolbar dark color="primary">
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="complete">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <h1 class="title font-weight-bold">{{regiao.nome}}</h1>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn small dark text class="d-flex align-center" @click="complete">
            <span class="text-capitalize d-block mt-1">Concluir</span>
            <v-icon size="18" class="ml-2">mdi-check-circle-outline</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-container>
    </v-toolbar>
    <v-container class="pa-5 pt-0 mt-5">
     <v-form>
        <v-text-field name="busca" label="Filtrar" @input="search" clearable></v-text-field>
      </v-form>
      <div v-if="loading" class="d-flex justify-center mt-10">
         <v-progress-circular color="blue" indeterminate></v-progress-circular>
      </div>
      <v-list-item-group color="primary" v-model="corretoras" multiple v-else>
        <v-list-item
          class=""
          v-for="(corretora, index) in dadosFiltrados"
          :key="index"
          :disabled="!corretoras.includes(corretora.cpfCnpj) && corretoras.length >= total"
          :value="corretora.cpfCnpj">
          <template v-slot:default="{ active }">
            <v-list-item-content>
              <v-list-item-title>
                <h2
                  class="white--text subtitle-1 font-weight-bold ma-1 ml-0"
                >{{corretora.nomeFantasia}}</h2>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div :class="active ? 'item-ativo' : ''"></div>
              <v-checkbox
                class="d-none"
                :disabled="3 > corretoras.length || active"
                :input-value="active"
                color="deep-purple accent-4"
              ></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-container>
  </fragment>
</template>

<script>
import { filterArrayObejctByString } from '@/utils/filterArray';
import corretoraService from '@/services/corretoraService';
import usuarioService from '@/services/usuarioService';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    regiao: {
      type: String,
      required: true,
    },
    removerCorretoras: {
      type: Array,
      default: () => [],
    },
    total: {
      default: 10,
    },
  },
  data() {
    return {
      corretoras: [],
      loading: false,
      dialog: false,
      data: [],
      dadosFiltrados: [],
    };
  },
  computed: {
    ...mapState({
      dataState: (state) => state.cadastroCorretor.corretoras,
      corretorState: (state) => state.user.profile,
    }),
  },
  watch: {
    dataState: {
      immediate: true,
      handler: 'setCorretoras',
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setCorretora: 'cadastroCorretor/setCorretora',
    }),
    search(value) {
      this.dadosFiltrados = filterArrayObejctByString({
        string: value,
        array: this.data,
        keyObject: 'nomeFantasia',
      });
    },
    complete() {
      const corretoras = this.dadosFiltrados.filter((c) => this.corretoras.includes(c.cpfCnpj));
      this.$emit('complete', corretoras);
    },
    setCorretoras() {
      this.corretoras = this.$cleanObserver(this.dataState.map((c) => c.cpfCnpj));
    },
    async getCorretorasExterno() {
      this.loading = true;
      try {
        const corretorasExcluir = await this.getCorretorasAtuais();
        let data = await corretoraService.getCorretorasParaVincular({
          uf: this.regiao,
          tipoCorretor: this.corretorState.tipoCorretor,
          subTipoCorretor: this.corretorState.subTipoCorretor,
        });

        data = data.filter((c) => corretorasExcluir.indexOf(c.cpfCnpj) === -1);
        data = data.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
        this.data = data;
        this.dadosFiltrados = data;
      } catch (error) {
        this.$root.$snackBar.open(
          {
            color: 'error',
            message: 'Erro ao buscar dados, tente novamente mais tarde',
          },
        );
      } finally {
        this.loading = false;
      }
    },
    async getCorretorasAtuais() {
      const corretoras = await usuarioService.listaCorretorasVinculadas({ cpf: this.corretorState.cpf });
      return corretoras.map((c) => c.cpfCnpj);
    },
    async getCorretoras() {
      this.loading = true;
      try {
        const corretorasExcluir = await this.getCorretorasAtuais();
        let data = await corretoraService.listaParaVincular({
          cpf: this.corretorState.cpf,
        });

        data = data.filter((c) => corretorasExcluir.indexOf(c.cpfCnpj) === -1);
        data = data.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
        this.data = data;
        this.dadosFiltrados = data;
        this.loading = false;
      } catch (error) {
        this.$root.$snackBar.open(
          {
            color: 'error',
            message: 'Erro ao buscar dados, tente novamente mais tarde',
          },
        );
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getCorretoras();
  },
};
</script>
<style lang="scss" scoped>
.item-ativo::after {
    width: 24px;
    min-height: 24px;
    color: #ffffffba;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    right: 10px;
    display: block;
    content: "\F05E0";
    font: normal normal normal 24px "Material Design Icons";
    padding: 0;
    margin: 0;
}
</style>
